import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';
import userUtils from "@/utils/userUtils";

Vue.use(VueRouter);

const routes = [
  {
    path: '/admin/assessmentroles',
    name: 'assessmentRoles',
    component: () => import('@/pages/admin/AssessmentRoles.vue'),
    meta: {
      requiresAuth: true,
      adminOnly: true
    }
  },
  {
    path: '/admin/assigncourses/:userid',
    base: '/',
    component: () => import('@/pages/admin/AssignCourses/AssignCourses.vue'),
    meta: {
      requiresAuth: true,
      adminOnly: true
    }
  },   
  {
    path: '/admin/courses',
    base: '/',
    component: () => import('@/pages/admin/Courses.vue'),
    meta: {
      requiresAuth: true,
      adminOnly: true
    }
  },  
  {
    path: '/admin/dashboard',
    name: 'adminDashboard',
    component: () => import('@/pages/admin/AdminDashboard.vue'),
    meta: {
      requiresAuth: true,
      adminOnly: true
    }
  },  
  {
    path: '/admin/groups/console',
    name: 'adminGroups',
    component: () => import('@/pages/admin/groups/GroupsConsole.vue'),
    meta: {
      requiresAuth: true,
      adminOnly: true
    }    
  },   
  {  
    path: '/admin/edituser/:userid',
    base: '/',
    component: () => import('@/pages/admin/EditUser'),
    meta: {
      requiresAuth: true,
      adminOnly: true
    }
  },  
  {
    path: '/admin/functiongroupfunctions/:id',
    base: '/',
    component: () => import('@/pages/admin/FunctionGroupFunctions.vue'),
    meta: {
      requiresAuth: true,
      adminOnly: true
    }
  },
  {
    path: '/admin/functions',
    base: '/',
    component: () => import('@/pages/admin/FunctionGroups.vue'),
    meta: {
      requiresAuth: true,
      adminOnly: true
    }
  },   
  {
    path: '/admin/linkusers/:userid',
    base: '/',
    component: () => import('@/pages/admin/LinkUsers/LinkUsers.vue'),
    meta: {
      requiresAuth: true,
      adminOnly: true
    }
  },     
  {
    path: '/admin/onlineClasses',
    base: '/',
    component: () => import('@/pages/admin/OnlineClasses.vue'),
    meta: {
      requiresAuth: true,
      adminOnly: true
    }
  },
  {
    path: '/admin/organisations',
    base: '/',
    component: () => import('@/pages/admin/Organisations.vue'),
    meta: {
      requiresAuth: true,
      adminOnly: true
    }
  },
  {
    path: '/admin/orgusers/:id',
    name: 'orgusers',
    component: () => import('@/pages/admin/OrganisationUsers.vue'),
    meta: {
      requiresAuth: true,
      adminOnly: true
    }
  },
  {
    path: '/admin/rolefunctions/:id',
    base: '/',
    component: () => import('@/pages/admin/RoleFunctions.vue'),
    meta: {
      requiresAuth: true,
      adminOnly: true
    }
  },
  {
    path: '/admin/registeruser',
    base: '/',
    component: () => import('@/pages/admin/RegisterUser'),
    meta: {
      requiresAuth: true,
      adminOnly: true
    }
  },  
  {
    path: '/admin/roles',
    base: '/',
    component: () => import('@/pages/admin/Roles.vue'),
    meta: {
      requiresAuth: true,
      adminOnly: true
    }
  },
  {
    path: '/admin/usermanagement',
    base: '/',
    component: () => import('@/pages/admin/UserManagement/UserManagement.vue'),
    meta: {
      requiresAuth: true,
      adminOnly: true
    }
  },
  {
    path: '/admin/useraudit/:organisationid/:userid',
    name: 'useraudit',
    component: () => import('@/pages/admin/UserAudit.vue'),
    meta: {
      requiresAuth: true,
      adminOnly: true
    }
  },
  {
    path: '/admin/temppasswords',
    base: '/',
    component: () => import('@/pages/admin/TempPasswords.vue'),
    meta: {
      requiresAuth: true,
      adminOnly: true
    }
  },
  {
    path: '/admin/reports/locks',
    name: 'adminReportLocks',
    base: '/',
    component: () => import('@/pages/admin/AdminReportLocks.vue'),
    meta: {
      requiresAuth: true,
      adminOnly: true
    }
  },
  {
    path: '/admin/reports/:role',
    name: 'adminReports',
    base: '/',
    component: () => import('@/pages/admin/AdminReports.vue'),
    meta: {
      requiresAuth: true,
      adminOnly: true
    }
  },
  {
    path: '/admin/components/paperDownloadLogs',
    name: 'paperDownloadLogs',
    component: () => import('@/pages/admin/components/PaperDownloadLogs.vue'),
    meta: {
      requiresAuth: true,
      adminOnly: true
    }
  },
  {
    path: '/admin/components/paperUploadLogs',
    name: 'paperUploadLogs',
    component: () => import('@/pages/admin/components/PaperUploadLogs.vue'),
    meta: {
      requiresAuth: true,
      adminOnly: true
    }
  },
  {
    path: '/admin/components/aPILogs',
    name: 'apiLogs',
    component: () => import('@/pages/admin/components/APILogs.vue'),
    meta: {
      requiresAuth: true,
      adminOnly: true
    }
  },
  {
    path: '/admin/components/questionPaperDownloadLogs',
    name: 'questionPaperDownloadLogs',
    component: () => import('@/pages/admin/components/QuestionPaperDownloadLogs.vue'),
    meta: {
      requiresAuth: true,
      adminOnly: true
    }
  },
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/pages/thisWeek/ThisWeek.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/learnerConsole',
    name: 'learnerConsole',
    component: () => import('@/pages/learnerConsole/LearnerConsole.vue'),
    meta: {
      requiresAuth: true
    }
  }, 
  {
    path: '/assessments/downloads',
    name: 'assessmentsdownloads',
    component: () => import('@/pages/assessments/AssessmentDownloads.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/assessments/downloads',
    name: 'assessmentDownloadsInternalAdmin',
    component: () => import('@/pages/assessments/AssessmentDownloadsInternalAdmin.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/assessments/reports',
    name: 'assessmentsReports',
    component: () => import('@/pages/assessments/AssessmentReports.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/assessments/downloadAndUploads',
    name: 'assessmentsUpAndDown',
    component: () => import('@/pages/assessments/AssessmentsSubmissionsAll.vue'),
    meta: {
      requiresAuth: true
    }
  }, 
  {
    path: '/assessments/:subject/:courseid',
    name: 'assessments',
    component: () => import('@/pages/assessments/AssessmentsSubmissionsAll.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/assessments/submissions',
    name: 'adminAssessments',
    component: () => import('@/pages/assessments/AssessmentsSubmissionsAll.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/assessments/submissions',
    name: 'assessmentsSubmissions',
    component: () => import('@/pages/assessments/AssessmentsSubmissionsAll.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin/assessmentPlanner",
    name: "assessmentPlanner",
    component: () => import('@/pages/admin/assessmentPlanner/AssessmentPlanner.vue'),
    meta: {
      requiresAuth: true,
      adminOnly: true
    }
  },
  {
    path: '/assessments/receiveManualAssessment',
    name: 'receiveManualAssessment',
    component: () => import('@/pages/assessments/manualAssessments/ReceiveManualAssessment.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/assessments/manualSubmissionsReport',
    name: 'manualSubmissionReport',
    component: () => import('@/pages/assessments/manualAssessments/ManualSubmissionsReport.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/lessons/:subject/:courseid',
    name: 'lessons',
    component: () => import('@/pages/lessons/Lessons.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/onlineClasses/:subject/:courseid',
    name: 'onlineClasses',
    component: () => import('@/pages/onlineClasses/OnlineClasses.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/onlineclass/:guid',
    name: 'onlineclass',
    component: () => import('@/pages/onlineClasses/JoinOnlineClassAsPublic.vue'),
    meta: {
      requiresAuth: false,
      anonymous: true
    }
  },
  {
    path: '/forgotPassword',
    name: 'forgotPassword',
    component: () => import('@/pages/password/ForgotPassword.vue'),
    meta: {
      requiresAuth: false,
      anonymous: true
    }
  },
  {
    path: '/recoverPassword',
    name: 'recoverPassword',
    component: () => import('@/pages/password/RecoverPassword.vue'),
    meta: {
      requiresAuth: false,
      anonymous: true
    }
  },  
  {
    path: '/api/:version/:functionName',
    name: 'apiRedirect',
    component: () => import('@/pages/api/Redirect.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('@/pages/calendar/Calendar.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/progress',
    name: 'progress',
    component: () => import('@/pages/progress/Progress.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/supplementaryProgress',
    name: 'supplementaryProgress',
    component: () => import('@/pages/progress/SupplementaryProgress.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/tutorReports',
    name: 'totorReportsports',
    component: () => import('@/pages/reports/TutorReports.vue'),
    meta: {
      requiresAuth: true
    }
  },   
  {
    path: '/extraResources/:subject/:courseid',
    name: 'extraResources',
    component: () => import('@/pages/extraResources/ExtraResources.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/Login.vue'),
    meta: {
    }
  },
  // {
  //   path: '/whatsNew',
  //   name: 'whatsNew',
  //   component: () => import('@/pages/WhatsNew.vue'),
  //   meta: {
  //     requiresAuth: true
  //   }
  // },  
  {
    path: '/underMaintenance',
    name: 'underMaintenance',
    component: () => import('@/pages/Maintenance.vue')
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/pages/NotFound.vue'),
    disableAuth: true
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {

  // check maintenance
  if (to.name != 'underMaintenance') {
    var config;
    var xhr = new XMLHttpRequest();
    xhr.open('GET', "/config.json?_=" + new Date().getTime(), false);
    xhr.onload = function() {
      var status = xhr.status;
      if (status === 200) {
        config = JSON.parse(xhr.response)
      }
    };
    xhr.send();

    if (config != null && config.maintenanceMode && !store.state.auth.maintenanceModeUser) {
      next({
        path: '/underMaintenance'
      })
    }
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {

    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.auth.authenticated) {
      next({
        path: '/login'
      })
      // next({
      //   path: '/login',
      //   query: { redirect: to.fullPath }
      // })
    } else {

      const role = userUtils.getRole();
      var adminOnly = false;
      if (to.matched.some(record => record.meta.adminOnly)) {
        adminOnly = true;
      }
      if (adminOnly && (role.roleId != 1) && (role.roleId != 5) && (role.roleId != 6) && (role.roleId != 10) && (role.roleId != 13) && (role.roleId != 14)) {
        
        // next({
        //   path: '/dashboard',
        //   query: { redirect: to.fullPath }
        // })  
        next({
          path: '/dashboard'
        })  

      } else {

        if (to.path == '/dashboard') {
          switch (role.roleId) {
            case 1: // system administrator
            case 5: // online classes administrator
            case 6: // Client Support
            case 10: // assessment administrator
            case 13: // internal administrator
            case 14: // internal administrator
              next({
                path: '/admin/dashboard'
              })
              break;
            case 2: // tutor
            case 9: // OS Teacher
              if (userUtils.getlearnerSelected()) {
                next()
              } else {
                next({
                  path: '/learnerConsole'
                })   
                // next({
                //   path: '/learnerConsole',
                //   query: { redirect: to.fullPath }
                // })   
              }
              next();
              break;
            case 12: // invigilator
              next({
                path: '/assessments/downloadAndUploads'
              }) 
              break
              
            default:
              next()
          }
        } else {
          next()
        }

      }

    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
