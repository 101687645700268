import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import markDeclarationCodes from "@/store/lookup/markDeclarationCodes"

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		auth: {
			user: {},
			authenticated: false,
			role: { roleId: 0 },
			menu: [],
			token: '',
			learners: [],
			selectedLearnerIndex: 0,
			qualifications: [],
			selectedQualificationIndex: 0,
			learnerSelected: false,
			cookieAgreement: null,
			tempUser: false,
			isSwitchedUser: false,
			fileSizeUploadLimit: 5,
			lastVersion: ''
		},
		lookup: {
			markDeclarationCodes: markDeclarationCodes
		}
	},
	mutations: {

		clearQualifications(state) {
			state.auth.qualifications = [];
			state.auth.selectedQualificationIndex = 0;			
		},

		login(state, payload) {
			if (state.auth.cookieAgreement == null || state.auth.cookieAgreement) {
				state.auth.user = payload.user;
				state.auth.user.userId = parseInt(state.auth.user.userId);
				state.auth.user.organisationId = parseInt(state.auth.user.organisationId);
				state.auth.user.organisationUserRoleId = parseInt(state.auth.user.organisationUserRoleId);
				state.auth.user.role = parseInt(state.auth.user.role);
				state.auth.user.roles = payload.roles;
				state.auth.token = payload.token;
				state.auth.qualifications = [];
				state.auth.selectedQualificationIndex = 0;
				state.auth.tempUser = payload.tempUser;
				state.auth.isSwitchedUser = payload.isSwitchedUser;
				state.auth.fileSizeUploadLimit = parseFloat(payload.fileSizeUploadLimit);
			}
		},

		setAuthenticated(state, payload) {
			state.auth.authenticated = payload;
		},

		setTutorLearners(state, data) {
			state.auth.tutor = data;
		},

		loadLearners(state, learners) {
			state.auth.learners = learners;
		},

		loadQualifications(state, qualifications) {
			state.auth.qualifications = qualifications;
		},

		loadSupplementaryStates(state, courseSummary) {
			state.auth.qualifications.courseSummary = courseSummary;
		},

		logout(state) {
			state.auth.user = {};
			state.auth.role = { roleId: 0 };
			state.auth.menu = [];
			state.auth.token = '';
			state.auth.qualifications = [];
			state.auth.learners = [];
			state.auth.selectedQualificationIndex = 0;
			state.auth.selectedLearnerIndex = 0;
			state.auth.learnerSelected = false;
			state.auth.tempUser = false
			state.admin = null
			state.auth.tutor = null
			// state.auth.cookieAgreement = null;
		},

		setCookieAgreement(state, agreement) {
			state.auth.cookieAgreement = agreement
			if (!agreement) {
				// state.auth = {}
				state.auth.authenticated = false;
				state.auth.user = {};
				state.auth.role = { roleId: 0 };
				state.auth.menu = [];
				state.auth.token = '';
				state.auth.qualifications = [];
				state.auth.learners = [];
				state.auth.selectedQualificationIndex = 0;
				state.auth.selectedLearnerIndex = 0;
				state.auth.learnerSelected = false;
			} else {
				location.reload();
			}
		},

		refreshToken(state, token) {
			state.auth.token = token;
		},

		setLearnerIndex(state, learnerIndex) {
			state.auth.selectedLearnerIndex = learnerIndex;
		},

		setQualification(state, qualificationIndex) {
			state.auth.selectedQualificationIndex = qualificationIndex;
		},
		
		setLearnerSelected(state, learnerSelected) {
			state.auth.learnerSelected = learnerSelected;
		},

		setRole(state, role) {
			state.auth.role = role;
		},

		setVersion(state, version) {
			state.auth.lastVersion = version;
		},

		resetLearnerSelected(state) {
			state.auth.learnerSelected = false
		},

		setAdminStudent(state, studentDetails) {
			state.admin = {}
			state.admin.qualificationId = studentDetails.qualificationId
			state.admin.roleId = studentDetails.roleId
			state.admin.name = studentDetails.name
			state.admin.surname = studentDetails.surname
			state.admin.studentNr = studentDetails.studentNr
			state.admin.qualification = studentDetails.qualification
			state.admin.studentOrganisationUserRoleId = studentDetails.studentOrganisationUserRoleId
			state.admin.organisationUserRoleId = studentDetails.organisationUserRoleId
			state.admin.periodId = studentDetails.periodId
			state.admin.period = studentDetails.periodName
			state.admin.studentOrganisationId = studentDetails.organisationId
		}
	},
	plugins: [createPersistedState()]
});

export default store
